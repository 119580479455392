<template>
  <el-container>
    <el-main>
      <el-row>
        <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
        <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
        <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
      </el-row>
      <el-row>
        <img :src="path" style="width:200px;height: auto" alt="">
      </el-row>
      <el-row>
        <OrderInfoWithCopyOrderNo :money="money" :order-no="orderNo"></OrderInfoWithCopyOrderNo>
      </el-row>
      <el-row>
        <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
      </el-row>
      <el-row>
        <PayButton button-name="我已截屏，点我打开微信扫一扫" :h5Url="h5Url" v-show="payButtonShow"></PayButton>
      </el-row>
      <el-row>
        <div style="text-align: left;font-weight: bold">
          <div>充值教程(必看！)：</div>
          <div>1.点复制订单号，再手机截图，然后打开微信扫码进群</div>
          <div>2.发送【订单号】</div>
          <div>3.核对金额后，发红包</div>
          <div>4.等待客服【接收红包】，即可上分</div>
          <div>5.请注意核对金额，订单一次性有效，请勿重复支付</div>
        </div>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import OrderInfo from "@/components/OrderInfo.vue";
import PayButton from "@/components/PayButton.vue";
import OrderInfoWithCopyOrderNo from "@/components/OrderInfoWithCopyOrderNo.vue";

export default {
  name: 'WXQHBSD',
  components: {
    OrderInfoWithCopyOrderNo,
    PayButton,
    OrderInfo,
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      dialogFlag: false,
      dialogShowClose: false,
      path: '',
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        //跳转
        this.h5Url = "weixin://"
        this.path = data.path
        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>
.el-row {
  margin-bottom: 5px !important;
}

.el-main {
  padding: 5px;
}
</style>
