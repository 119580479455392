import Vue from 'vue'
import VueRouter from 'vue-router'
import TBDAIFU from '../views/TBDAIFU.vue'
import Test from '../views/Test.vue'
import AlipayUid from "@/views/AlipayUid.vue";
import AlipayAA from "@/views/AlipayAA.vue";
import WxScan from "@/views/WxScan.vue";
import XianyuZF from "@/views/XianyuZF.vue";
import AlipayYinlian from "@/views/AlipayYinlian.vue";
import AlipayFxc from "@/views/AlipayFxc.vue";
import ManualAlipayUid from "@/views/ManualAlipayUid.vue";
import TaobaoZF from "@/views/TaobaoZF.vue";
import AlipayXhb from "@/views/AlipayXhb.vue";
import AlipayLs from "@/views/AlipayLs.vue";
import TaobaoGame from "@/views/TaobaoGame.vue";
import Tk from "@/views/Tk.vue";
import TkCreateOrder from "@/views/TkCreateOrder.vue";
import AlipayDmf from "@/views/AlipayDmf.vue";
import AlipayDmfFenzhang from "@/views/AlipayDmfFenzhang.vue";
import Douyin from "@/views/Douyin.vue";
import AlipayWap from "@/views/AlipayWap.vue";
import AlipayWapFenzhang from "@/views/AlipayWapFenzhang.vue";
import Wanmei from "@/views/Wanmei.vue";
import AlipayorderPay from "@/views/AlipayorderPay.vue";
import Tl from "@/views/Tl.vue";
import TlCreateOrder from "@/views/TlCreateOrder.vue";
import DouyinCreateOrder from "@/views/DouyinCreateOrder.vue";
import Shj from "@/views/Shj.vue";
import DmfLittleCreateOrder from "@/views/DmfLittleCreateOrder.vue";
import OrderLittleOrder from "@/views/OrderLittleOrder.vue";
import AlipayYinlianBak from "@/views/AlipayYinlianBak.vue";
import Lpk from "@/views/Lpk.vue";
import LpkSYT from "@/views/LpkSYT.vue";
import AlipayRedpacket from "@/views/AlipayRedpacket.vue";
import DouyinZhuanzhang from "@/views/DouyinZhuanzhang.vue";
import AlipayPage from "@/views/AlipayPage.vue";
import AlipayDf from "@/views/AlipayDf.vue";
import Jym from "@/views/Jym.vue";
import Ycys from "@/views/Ycys.vue";
import Zhongji from "@/views/Zhongji.vue";
import JDDF from "@/views/JDDF.vue";
import AlipayApp from "@/views/AlipayApp.vue";
import Zft3rd from "@/views/Zft3rd.vue";
import Smshj from "@/views/Smshj.vue";
import AlipayAppSyt from "@/views/AlipayAppSyt.vue";
import BalanceDf from "@/views/BalanceDf.vue";
import Jddfm from "@/views/Jddfm.vue";
import Wxsd from "@/views/Wxsd.vue";
import Dyyshj from "@/views/Dyyshj.vue";
import Juliang from "@/views/Juliang.vue";
import AlipaySkd from "@/views/AlipaySkd.vue";
import WxXy from "@/views/WxXy.vue";
import Pubu from "@/views/Pubu.vue";
import WeiDian from "@/views/WeiDian.vue";
import WeiDian2 from "@/views/WeiDian2.vue";
import Wxjyskd from "@/views/Wxjyskd.vue";
import WXQHBSD from "@/views/WXQHBSD.vue";
import Wxjyskdsd from "@/views/Wxjyskdsd.vue";
import ZftWap from "@/views/ZftWap.vue";
import Jsapi from "@/views/Jsapi.vue";
import Zdt from "@/views/Zdt.vue";
Vue.use(VueRouter)

const routes = [
    {
        path: '/test',
        name: 'Test',
        component: Test,
        meta: {
            title: "测试下单"
        }
    },
    {
        path: '/alipay_xianyu',
        name: 'tbdaifu',
        component: TBDAIFU,
        meta: {
            title: "TB代付H5"
        }
    },
    {
        path: '/alipay_uid',
        name: 'alipayUid',
        component: AlipayUid,
        meta: {
            title: "支付宝金条"
        }
    },
    {
        path: '/alipay_aa',
        name: 'alipayAA',
        component: AlipayAA,
        meta: {
            title: "支付宝AA"
        }
    },
    {
        path: '/wx_scan',
        name: 'wxScan',
        component: WxScan,
        meta: {
            title: "微信扫码"
        }
    },
    {
        path: '/xianyu_zf',
        name: 'xianyuZf',
        component: XianyuZF,
        meta: {
            title: "闲鱼直付"
        }
    },
    {
        path: '/alipay_yinlian',
        name: 'alipayYinlian',
        component: AlipayYinlian,
        meta: {
            title: "支付宝银联"
        }
    },
    {
        path: '/alipay_fxc',
        name: 'alipayFxc',
        component: AlipayFxc,
        meta: {
            title: "支付宝放心充"
        }
    },
    {
        path: '/manual_alipay_uid',
        name: 'manual_alipay_uid',
        component: ManualAlipayUid,
        meta: {
            title: "支付宝uid手动回调"
        }
    },
    {
        path: '/taobao_zf',
        name: 'taobao_zf',
        component: TaobaoZF,
        meta: {
            title: "淘宝直付"
        }
    },
    {
        path: '/alipay_xhb',
        name: 'alipayXhb',
        component: AlipayXhb,
        meta: {
            title: "支付宝小荷包"
        }
    },
    {
        path: '/alipay_ls',
        name: 'alipayLs',
        component: AlipayLs,
        meta: {
            title: "支付宝LS"
        }
    },
    {
        path: '/taobao_game',
        name: 'taobao_game',
        component: TaobaoGame,
        meta: {
            title: "淘宝直付-游戏"
        }
    },
    {
        path: '/tk',
        name: 'tk',
        component: Tk,
        meta: {
            title: "tk"
        }
    },
    {
        path: '/tkCreateOrder',
        name: 'tkCreateOrder',
        component: TkCreateOrder,
        meta: {
            title: "收银台"
        }
    },
    {
        path: '/alipay_dmf',
        name: 'alipay_dmf',
        component: AlipayDmf,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipay_dmf_fenzhang',
        name: 'alipay_dmf_fenzhang',
        component: AlipayDmfFenzhang,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/douyin',
        name: 'douyin',
        component: Douyin,
        meta: {
            title: "抖音微播"
        }
    },
    {
        path: '/alipay_wap',
        name: 'alipay_wap',
        component: AlipayWap,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipay_wap_fenzhang',
        name: 'alipay_wap_fenzhang',
        component: AlipayWapFenzhang,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/wanmei',
        name: 'wanmei',
        component: Wanmei,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipay_order_pay',
        name: 'alipay_order_pay',
        component: AlipayorderPay,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/tl',
        name: 'tl',
        component: Tl,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/tlCreateOrder',
        name: 'tlCreateOrder',
        component: TlCreateOrder,
        meta: {
            title: "收银台"
        }
    },
    {
        path: '/dmfLittleCreateOrder',
        name: 'dmfLittleCreateOrder',
        component: DmfLittleCreateOrder,
        meta: {
            title: "收银台"
        }
    },
    {
        path: '/orderLittleOrder',
        name: 'orderLittleOrder',
        component: OrderLittleOrder,
        meta: {
            title: "收银台"
        }
    },
    {
        path: '/douyinCreateOrder',
        name: 'douyinCreateOrder',
        component: DouyinCreateOrder,
        meta: {
            title: "收银台"
        }
    },
    {
        path: '/shj',
        name: 'shj',
        component: Shj,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/lpk',
        name: 'lpk',
        component: Lpk,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/lpkSyt',
        name: 'lpkSyt',
        component: LpkSYT,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipay_redpacket',
        name: 'alipayRedpacket',
        component: AlipayRedpacket,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/douyin_zhuanzhang',
        name: 'douyin_zhuanzhang',
        component: DouyinZhuanzhang,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipayPage',
        name: 'alipayPage',
        component: AlipayPage,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipay_dfck',
        name: 'alipayDf',
        component: AlipayDf,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/jym',
        name: 'jym',
        component: Jym,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/ycys',
        name: 'ycys',
        component: Ycys,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/zj',
        name: 'zj',
        component: Zhongji,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/jddf',
        name: 'jddf',
        component: JDDF,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/alipay_app',
        name: 'alipay_app',
        component: AlipayApp,
        meta: {
            title: "支付宝App"
        }
    },
    {
        path: '/zft3rd',
        name: 'zft3rd',
        component: Zft3rd,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/smshj',
        name: 'smshj',
        component: Smshj,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipayAppSyt',
        name: 'alipayAppSyt',
        component: AlipayAppSyt,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/balance_df',
        name: 'balanceDf',
        component: BalanceDf,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/jddfm',
        name: 'jddfm',
        component: Jddfm,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/wx_sd',
        name: 'wxsd',
        component: Wxsd,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/dyyshj',
        name: 'dyyshj',
        component: Dyyshj,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/jl',
        name: 'juliang',
        component: Juliang,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/alipay_skd',
        name: 'alipay_skd',
        component: AlipaySkd,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/wx_xy',
        name: 'wx_xy',
        component: WxXy,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/pubu',
        name: 'pubu',
        component: Pubu,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/weidian',
        name: 'weidian',
        component: WeiDian,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/weidian2',
        name: 'weidian2',
        component: WeiDian2,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/jyskd',
        name: 'jyskd',
        component: Wxjyskd,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/wxqhbsd',
        name: 'wxqhbsd',
        component: WXQHBSD,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/jyskdsd',
        name: 'jyskdsd',
        component: Wxjyskdsd,
        meta: {
            title: "微信付款"
        }
    },
    {
        path: '/zft',
        name: 'zftWap',
        component: ZftWap,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/jsapi',
        name: 'jsapi',
        component: Jsapi,
        meta: {
            title: "支付宝付款"
        }
    },
    {
        path: '/zdt',
        name: 'zdt',
        component: Zdt,
        meta: {
            title: "支付宝付款"
        }
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router
