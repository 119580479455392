<template>
  <div>
    <el-container>
      <el-main>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>

          <el-col :span="6">
            订单金额：
          </el-col>
          <el-col :span="12">
            <span class="moneyTitle2">{{ money }}</span>元
          </el-col>
          <el-col :span="6">
            <el-button class="copyMoney" type="primary" size="mini" :data-clipboard-text="money" @click="handleCopy">
              点我复制
            </el-button>
          </el-col>
        </el-row>

        <div v-show="isTrans">
          <el-row>
            <el-col :span="6">
              收款账号:
            </el-col>
            <el-col :span="12">
              <span class="moneyTitle2">{{ transAccount }}</span>
            </el-col>
            <el-col :span="6">
              <el-button class="copyAccount" type="primary" size="mini" :data-clipboard-text="transAccount"
                         @click="handleCopyAccount">
                点我复制
              </el-button>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              收款姓名:
            </el-col>
            <el-col :span="12">
              <span class="moneyTitle2">{{ name }}</span>
            </el-col>
            <el-col :span="6">
              <el-button class="copyName" type="primary" size="mini" :data-clipboard-text="name"
                         @click="handleCopyName">
                点我复制
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div class="orderInfo">
          <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
        </div>
        <div class="orderInfo">订单号：
          <el-tag size="small">{{ orderNo }}</el-tag>
        </div>

        <el-row>
          <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
        </el-row>
        <el-row>
          <el-button v-show="transButtonShow" type="primary" @click="handleCopyJump" class="copyMoneyJump" :data-clipboard-text="money">点击复制金额跳转支付宝支付</el-button>
        </el-row>
        <el-row style="text-align: left;color: red;font-weight: bold">
          <div v-show="isTrans">
<!--                        <div>1. 点击复制支付宝号</div>
                        <div>2. 打开支付宝到 转账 界面，粘贴支付宝号，点击下一步</div>
                        <div>3. 回到当前页面，复制订单金额</div>
                        <div>4. 回到支付宝，粘贴订单金额</div>
                        <div>付款时如果提示要求输入收款人姓名，请复制当前页面的收款人姓名!</div>
                        <p><span style="font-weight: bold;color: black;">注意:<br>
                        付款时一定要输入当前订单金额，精确到小数，否则付款失败！<br>
                        请勿超时支付，否则付款失败！</span>
                        </p>-->
            <p>付款流程：</p>
            <p>1.打开支付宝选择转账</p>
            <p>2.根据订单页面的金额、收款账号、姓名手动进行转账</p>
            <p>3.请一定完全按照订单页面的金额进行付款，否则不能实时到账</p>
            <p>4.该账号只能支付一次，请勿重复转账</p>
<!--            付款教程:
            <img src="../assets/trans.jpg" width="100%" alt="">-->
          </div>
          <div v-show="isTrans === false">
            <div>1. 点击复制金额按钮</div>
            <div>2. 点击启动支付宝付款，粘贴付款金额</div>
            <p><span style="font-weight: bold;color: black;">注意:<br>
            付款时一定要输入当前订单金额，精确到小数，否则付款失败！<br>
            请勿超时支付，否则付款失败！</span>
            </p>
          </div>
        </el-row>
        <el-row>
          <el-dialog
              title="请输入付款人姓名,不可乱填"
              width="90%"
              :visible="dialogFlag"
              :show-close="dialogShowClose"
              center
          >
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
              <el-form-item label="订单id" prop="orderId" v-show="false">
                <el-input v-model="ruleForm.orderId"></el-input>
              </el-form-item>
              <el-form-item label="付款人姓名" prop="payerName">
                <el-input v-model="ruleForm.payerName"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm">提 交</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import WxLogo from "@/components/WxLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import Bank from "@/components/Bank.vue";
import ClipboardJS from "clipboard";
import AlipayButton from "@/components/AlipayButton.vue";

export default {
  name: 'wxScan',
  components: {
    AlipayButton,
    WxLogo,
    PayTips,
    CountDown,
    Qrcode,
    Bank,
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      h5Url: '',
      isTrans: false,
      transAccount: '',
      name: '',
      dialogFlag: false,
      dialogShowClose: false,
      transButtonShow: false,
      ruleForm: {
        orderId: '',
        payerName: ''
      },
      rules: {
        orderId: [
          {required: true, message: '请输入订单id', trigger: 'blur'},
        ],
        payerName: [
          {required: true, message: '请输入付款人姓名', trigger: 'blur'},
          {min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    getData() {
      //请求订单信息
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }

        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)

        this.money = data.payMoney
        this.ruleForm.orderId = data.orderId
        //如果有支付宝账号，则为转账模式
        if (data.account && data.account !== '') {
          this.isTrans = true
          this.name = data.name
          this.transAccount = data.account
          this.dialogFlag = true
        } else {
          this.qrShow = true
          this.payButtonShow = true
          this.h5Url = this.$utils.getAlipayLs(data.pathUnq)
        }
      }).catch(() => {
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //处理复制金额
    handleCopy() {
      const clipboard = new ClipboardJS('.copyMoney');
      clipboard.on('success', (e) => {
        this.$message.success("成功复制金额:" + e.text)
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制金额失败，请手动填写金额")
        clipboard.destroy(); // 取消事件监听
      });
    },
    //处理复制转账账号
    handleCopyAccount() {
      const clipboard = new ClipboardJS('.copyAccount');
      clipboard.on('success', (e) => {
        this.$message.success("成功复制账号:" + e.text)
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制失败，请手动填写")
        clipboard.destroy(); // 取消事件监听
      });
    },
    //处理复制姓名
    handleCopyName() {
      const clipboard = new ClipboardJS('.copyName');
      clipboard.on('success', (e) => {
        this.$message.success("成功复制姓名:" + e.text)
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制失败，请手动填写")
        clipboard.destroy(); // 取消事件监听
      });
    },
    //处理复制金额
    handleCopyJump() {
      const clipboard = new ClipboardJS('.copyMoneyJump');
      clipboard.on('success', (e) => {
        this.$message({
          message: "已复制金额，前去支付宝粘贴付款", type: "success", duration: 2000, onClose: () => {
            window.location.href = this.$utils.getAlipayTrans(this.transAccount)
          }
        })
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制金额失败，请手动填写金额")
        clipboard.destroy(); // 取消事件监听
      });
    },
    //提交表单
    submitForm(ruleForm) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          Request.post("/show/orderUser", this.ruleForm).then(res => {
            if (res.code !== 0) {
              this.$message({message: res.msg, type: 'error',})
              return
            }
            //关闭弹窗
            this.dialogFlag = false
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>

</style>
