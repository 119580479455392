<template>
  <div>
    <el-container>
      <el-main>
        <AlipayLogo></AlipayLogo>
        <el-row>
          <Qrcode :content="qrUrl" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元</div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <div class="arrow-container" style="text-align: center; color: green; margin: 0;" v-show="sdTipShow">
          <!-- 左箭头 -->
<!--          <div class="arrow-text arrow-left">→</div>-->

          <!-- 中间文字 -->
          <p class="text">截图扫码秒到账</p>

          <!-- 右箭头 -->
<!--          <div class="arrow-text arrow-right">←</div>-->
        </div>
        <p style="margin: 0;font-size: 18px;text-align: center;color: red;font-weight: bold" v-show="sdTipShow">请截图保存二维码到相册，打开支付宝扫码付款</p>
        <el-row>
          <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
        </el-row>
        <p style="font-size: 20px;font-weight: bold;color:red;text-align: left" v-show="payButtonShow">
          如遇风险提醒，请继续完成支付，即可到账<br/>
          如弹出加好友提示，无需添加好友直接点击继续支付 即可付款成功
        </p>

        <div style="color:red;text-align: left" v-show="sendCodeTips">如遇到发验证码请放心输入,验证码是支付宝官方确认安全的验证 放心输入才能充值成功</div>
        <el-row>
          <pay-tips></pay-tips>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";

export default {
  name: 'AlipayUid',
  components: {
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      qrUrl: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      sendCodeTips:false,
      sdTipShow: false,
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        this.money = data.money
        if (this.money>=500 || this.money === 10){
          this.sdTipShow = true
          this.payButtonShow = false
        } else {
          this.payButtonShow = true
        }
        let qrcode = data.pathContent
        //https://render.alipay.com/p/yuyan/180020010001203259/index.html?biz_no=2024111904200300001073884926_1db1910085b88ea95e3a162b333897b4
        if (qrcode.includes("https://render.alipay.com/p/yuyan")) {
          this.h5Url = this.$utils.getAlipayXhb(qrcode)
          this.qrUrl = qrcode

        } else {
          if (qrcode.includes("alipay.com")) {
            //如果是pdd代付类型
            //window.location.href = qrcode
            if (qrcode.includes("biz_no=")) {
              //判断是否是淘宝代付，结尾带orderIds参数
              // if (qrcode.includes("channel=qr_code&orderIds=")) {
              //   this.h5Url = this.$utils.getTbDaifu(qrcode)
              //   this.qrUrl = qrcode
              //   this.sendCodeTips = true
              //   console.log(qrcode)
              //   console.log(this.h5Url)
              //   return;
              // }
              //判断是否是淘宝代付，结尾带m.tb.cn参数
              if (qrcode.includes("m.tb.cn")) {
                const regex = /m\.tb\.cn\/h\.[^&?]+(\?[^&]*)?/;
                const match = qrcode.match(regex);
                if (match) {
                  // console.log(match[0]); // 输出: m.tb.cn/h.T4igYQ1?_tbScancodeApproach_=scan
                  this.h5Url = "taobao://"+match[0]
                  this.qrUrl = qrcode
                  this.sendCodeTips = true
                  console.log(qrcode)
                  console.log(this.h5Url)
                  return;
                } else {
                  console.log("付款码匹配错误，请重新下单");
                  return;
                }
              }
              this.h5Url = this.$utils.getXyH52(qrcode)
              this.qrUrl = "https://mclient.alipay.com/h5/peerpay.htm?enableWK=YES&biz_no=" + qrcode.match(/biz_no=([^&]+)&app_name/)[1] + "&app_name=tb&sc=card&__webview_options__=pd%3dNO"
            } else {
              this.h5Url = this.$utils.getZhouzhuan(qrcode)
              this.qrUrl = qrcode
            }
          } else {
            this.h5Url = this.$utils.getXyH5(data.pathContent)
            this.qrUrl = data.pathContent
          }
        }

      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.sdTipShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
        this.sdTipShow = false
      }
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>

<style scoped>

.orderInfo{
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}
/* 父容器布局 */
.arrow-container {
  margin: 0; /* 极小的上下外边距 */
  padding: 0; /* 内边距保持为 0 */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* 再次缩小箭头和文字间距 */
}

.text {
  font-size: 26px; /* 再次减小字体大小 */
  font-weight: bold;
  color: #79e811;
  margin: 0; /* 确保没有多余的外边距 */
  line-height: 1; /* 减小文字的行高 */
}

.arrow-text {
  font-size: 45px; /* 缩小箭头大小 */
  font-weight: bold;
  color: red;
  animation-duration: 0.8s; /* 动画速度更快 */
  animation-iteration-count: infinite;
}

.arrow-left {
  animation-name: slide-left;
}

@keyframes slide-left {
  0%, 100% {
    transform: translateX(-4px); /* 减少位移量 */
  }
  50% {
    transform: translateX(0);
  }
}

.arrow-right {
  animation-name: slide-right;
}

@keyframes slide-right {
  0%, 100% {
    transform: translateX(4px); /* 减少位移量 */
  }
  50% {
    transform: translateX(0);
  }
}


</style>